import React from 'react'
import { Button, Modal } from 'react-bootstrap';


const ModalSimNao = ({ children, show, handleClose, handleConfirm }) => {


    return (

        <Modal show={show} onHide={() =>handleClose()}>
            <Modal.Body>
                {children}
            </Modal.Body>
            <Modal.Footer>
                <Button variant='danger' size='xs' onClick={() => handleClose()}>Não</Button>
                <Button variant='primary' size='xs' onClick={() => handleConfirm()}>Sim</Button>
            </Modal.Footer>
        </Modal>
    )


}


export default ModalSimNao;