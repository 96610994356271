

const getOptionsSexoAnimais = () => {
    return [{
        'id': 'M',
        'nome': 'Macho'
    }, {
        'id': 'F',
        'nome': 'Fêmea'
    }
    ];
}

const getOptionsPortes = () => {
    return [{
        'id': 9,
        'nome': 'Pequeno'
    }, {
        'id': 10,
        'nome': 'Médio'
    }, {
        'id': 11,
        'nome': 'Grande'
    }, {
        'id': 12,
        'nome': 'Extra Grande'
    },
    ];
}





export const Commons = {

    getOptionsSexoAnimais,
    getOptionsPortes
}