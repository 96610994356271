import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { ClienteService } from "../../services/api/Clientes/ClienteService";
const ClienteList = ({ listCliente, handleDelete }) => {


    return (
        <>

            {listCliente.map((item) => (
                <tr>
                    <td>{item.id}</td>
                    <td><a href={'/clientes/' + item.id} title="Ver dados">{item.nome + ' ' + item.sobrenome}</a></td>
                    <td>
                    
                        {item?.animais?.map((animal,key ) => (
                            <>
                                <a href={'/animais/ficha/' + animal.id}>{animal.nome}</a>
                                {((key+1) < item.animais.length ) ? ', ' : ''}
                            </>


                        ))}
                    </td>
                    <td>{item.alerta}</td>
                    <td className="text-center">

                        <Button size="sm" variant="info" href={'/clientes/cadEdit/' + item.id} title="Editar" className="mr-10"><FontAwesomeIcon icon={faPenToSquare} /></Button>
                        <Button size="sm" variant="danger" title="Excluir" onClick={() => handleDelete(item.id)}><FontAwesomeIcon icon={faTrash} /></Button>
                    </td>
                </tr>


            ))}
        </>
    )

}

export default ClienteList;