import { useEffect, useState } from "react"
import { Button, Col, Container, Form, Row, Tab, Tabs } from "react-bootstrap"
import Template from "../../layout/Template"
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from "zod"
import { ClienteService } from "../../services/api/Clientes/ClienteService"
import AnimaisClientes from "../Animais/AnimaisClientes"
import {  useNavigate, useParams } from "react-router-dom"



const scheme = z.object({
    tiposPessoasId: z.string(),
    nome: z.string().nullable(),
    razaoSocial: z.string().nullable(),
    sobrenome: z.string().nullable(),
    cpf: z.coerce.string().nullable(),
    sexo: z.string().nullable(),
    email: z.string().email().nullable(),
    telefone: z.coerce.string().nullable(),
    celular: z.string().nullable().nullable(),
    cep: z.coerce.string().nullable(),
    logradouro: z.string().nullable(),
    numero: z.coerce.string().nullable(),
    complemento: z.string().nullable(),
    bairro: z.string().nullable(),
    cidade: z.string().nullable(),
    uf: z.string().nullable(),
    origemContatoId: z.string().nullable(),
    observacoes: z.string().nullable(),
    alerta: z.string().nullable(),



}).refine((data) => { return !(data.tiposPessoasId.toString() === '1' && data.nome == '') }, {
    message: "O Nome é obrigatório",
    path: ["nome"], // path of error
}).refine((data) => { return !(data.tiposPessoasId.toString() === '2' && data.razaoSocial == '') }, {
    message: "A Razão Social é obrigatória",
    path: ["razaoSocial"], // path of error
})

const ClienteCadEdit = () => {

    const navigate = useNavigate()
    const { idCliente } = useParams();
    const [cliente, setCliente] = useState(null)
    const { register, handleSubmit, watch, formState: { errors } } = useForm({
        mode: 'onBlur',
        resolver: zodResolver(scheme),
        values: cliente
    })


    const [showAnimais, setShowAnimais] = useState(false)
    const [keyTab, setKeyTab] = useState('dadosPessoais')
    const [isPessoaJuridica, setIsPessoaJuridica] = useState(false)
    const watchShowJuridica = watch('tiposPessoasId')



    const onSubmit = async (data) => {
  console.log(data)
    

        if (idCliente != undefined) {
            const res = await ClienteService.updateById(idCliente, data)
           // console.log(res)
            if (res.success) {
               
                navigate('/clientes')
            }


        } else {
            const res = await ClienteService.store(data)
          
            if (res.success) {
                navigate('/clientes')
            }
        }

    }

    // console.log((idCliente))

    useEffect(() => {

        if (idCliente != undefined) {
            const fetchData = async () => {
                const data = await ClienteService.getById(idCliente)
                const cli = data.data
                setCliente({
                    ...cli,
                    tiposPessoasId: (cli?.tiposPessoas?.id) ? cli?.tiposPessoas?.id.toString() : ''
                })

            }
            fetchData()



            setShowAnimais(true)
        }

    }, [])



    useEffect(() => {
        if (watchShowJuridica == 2) {
            setIsPessoaJuridica(true)
        } else {
            setIsPessoaJuridica(false)
        }

        console.log(watchShowJuridica)
    }, [watchShowJuridica])


    //console.log(errors)
    return (
        <>
            <Template>

                <Container>
                    <h3>Clientes - Cadastro</h3>


                    <div style={{ maxWidth: '800px' }}>


                        <Tabs
                            activeKey={keyTab}
                            onSelect={(k) => setKeyTab(k)}
                            className="mb-3">

                            <Tab eventKey="dadosPessoais" title="Dados Pessoais" >
                                <Form>
                                    <Row>
                                        <Form.Group >
                                            <Form.Label>Tipo de Pessoa:</Form.Label><br />
                                            <Form.Check
                                                inline
                                                type="radio"
                                                label="Pessoa Física"
                                                value="1"

                                                {...register('tiposPessoasId')}
                                            ></Form.Check>
                                            <Form.Check
                                                inline
                                                type="radio"
                                                label="Pessoa Jurídica"
                                                value="2"
                                                {...register('tiposPessoasId')}></Form.Check>
                                        </Form.Group>
                                    </Row>

                                    <div className="divPessoaJuridica" style={{ display: (isPessoaJuridica) ? '' : 'none' }}>
                                        <Row >
                                            <Form.Group as={Col} controlId="formRazaoSocial">
                                                <Form.Label>Razão Social</Form.Label>
                                                <Form.Control type="text" placeholder="Razão Social"
                                                    {...register('razaoSocial')}
                                                    isInvalid={!!(errors?.razaoSocial)}
                                                ></Form.Control>
                                            </Form.Group>
                                            <Form.Control.Feedback type='invalid' >
                                                {errors?.razaoSocial?.message}
                                            </Form.Control.Feedback>

                                        </Row>
                                        <Row >
                                            <Form.Group as={Col} controlId="formNomeFantasia" xs={8}>
                                                <Form.Label>Nome Fantasia</Form.Label>
                                                <Form.Control type="text" placeholder="Nome Fantasia"
                                                    isInvalid={false}
                                                    {...register('nomeFantasia')}
                                                ></Form.Control>
                                                <Form.Control.Feedback type="invalid">

                                                </Form.Control.Feedback>

                                            </Form.Group>
                                            <Form.Group as={Col} controlId="formCnpj" xs={4} >
                                                <Form.Label>CNPJ:</Form.Label>
                                                <Form.Control type="text" placeholder="CNPJ"
                                                    {...register('cnpj')}
                                                ></Form.Control>
                                            </Form.Group>
                                        </Row>


                                    </div>

                                    <div className="divPessoaFisica" style={{ display: (!isPessoaJuridica) ? '' : 'none' }}>

                                        <Row className="lg-5" >
                                            <Form.Group as={Col} controlId="formNome" xs={6}>
                                                <Form.Label>Nome*</Form.Label>
                                                <Form.Control type="text" placeholder="Nome"
                                                    {...register('nome')}
                                                    isInvalid={!!errors?.nome}
                                                />
                                                <Form.Control.Feedback type='invalid'>
                                                    {errors?.nome?.message}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                            <Form.Group as={Col} controlId="formSobrenome" xs={6}>
                                                <Form.Label>Sobrenome</Form.Label>
                                                <Form.Control type="text" placeholder="Sobrenome"
                                                    {...register('sobrenome')}
                                                />
                                            </Form.Group>
                                        </Row>
                                        <Row >
                                            <Form.Group as={Col} md={4} controlId="formCpf">
                                                <Form.Label>CPF:</Form.Label>
                                                <Form.Control type="text" placeholder="CPF"
                                                    {...register('cpf')} />
                                            </Form.Group>
                                            <Form.Group as={Col} md={4} controlId="formSexo">
                                                <Form.Label>Sexo:</Form.Label>
                                                <Form.Select placeholder="Sexo"
                                                    {...register('sexo')}>
                                                    <option value=''>Selecione...</option>
                                                    <option value='M'>Masc</option>
                                                    <option value='F'>Fem</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Row>
                                    </div>
                                    <Row>
                                        <Form.Group as={Col} controlId="formEmail" xs={12} md={4}>
                                            <Form.Label>E-mail</Form.Label>
                                            <Form.Control type="text" placeholder="E-mail"
                                                {...register('email')} />
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formTelefone" className="align-self-start">
                                            <Form.Label>Telefone</Form.Label>
                                            <Form.Control type="text" placeholder="Telefone"
                                                {...register('telefone')}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formCelular">
                                            <Form.Label>Celular</Form.Label>
                                            <Form.Control type="text" placeholder="Celular"
                                                {...register('celular')}
                                            ></Form.Control>
                                        </Form.Group>
                                    </Row>
                                    <hr></hr>
                                    <Row>
                                        <Form.Group as={Col} md={4} controlId="formCep">
                                            <Form.Label>CEP</Form.Label>
                                            <Form.Control type="text" placeholder="CEP"
                                                {...register('cep')}></Form.Control>
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <Form.Group as={Col} controlId="formLogradouro">
                                            <Form.Label>Logradouro</Form.Label>
                                            <Form.Control type="text" placeholder="Logradouro"
                                                {...register('logradouro')} />
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formLNumero" md={2}>
                                            <Form.Label>Número</Form.Label>
                                            <Form.Control type="text" placeholder="Numero"
                                                {...register('numero')}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formComplemento">
                                            <Form.Label>Complemento</Form.Label>
                                            <Form.Control type="text" placeholder="Complemento"
                                                {...register('complemento')} />
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <Form.Group as={Col} controlId="formBairro">
                                            <Form.Label>Bairro</Form.Label>
                                            <Form.Control type="text" placeholder="Bairro"
                                                {...register('bairro')}></Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formCidade">
                                            <Form.Label>Cidade</Form.Label>
                                            <Form.Control type="text" placeholder="Cidade"
                                                {...register('cidade')}></Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formUf">
                                            <Form.Label>UF</Form.Label>
                                            <Form.Control type="text" placeholder="UF"
                                                {...register('uf')} />
                                        </Form.Group>
                                    </Row>
                                    <hr></hr>
                                    <Row>

                                        <Form.Group as={Col} md={4} controlId="formOrigrmContato">
                                            <Form.Label>Origem do contato:</Form.Label>
                                            <Form.Select placeholder="Origem do contato"
                                                {...register('origem_contato')}>
                                                <option value=''>Selecione...</option>
                                            </Form.Select>
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formObservacao">
                                            <Form.Label>Observações</Form.Label>
                                            <Form.Control as="textarea" placeholder="Observações"
                                                {...register('observacoes')}></Form.Control>
                                        </Form.Group>

                                    </Row>
                                    <Row>
                                        <Form.Group as={Col} controlId="formAlerta">
                                            <Form.Label>Alerta</Form.Label>
                                            <Form.Control as="textarea" placeholder="Alerta"
                                                {...register('alerta')}></Form.Control>
                                        </Form.Group>
                                    </Row>
                                    <div className="text-center ">
                                        <Button variant="danger" className="mr-15" href="/clientes">Cancelar</Button>
                                        <Button variant="primary" onClick={() => handleSubmit(onSubmit)()}>Salvar</Button>
                                    </div>

                                </Form>
                            </Tab>
                            <Tab eventKey='animais' title="Animais" tabClassName={!showAnimais ? 'd-none' : ''}>
                                <AnimaisClientes />
                            </Tab>
                        </Tabs>

                    </div>
                </Container>
            </Template>

        </>
    )
}
export default ClienteCadEdit