import axios from "axios";
import { ResponseInterceptor } from "./ResponseInterceptor";
import { ErrorInterceptor } from "./ErrorInterceptor";

const API_URL = process.env.REACT_APP_API_URL_SISTEMA;
console.log(API_URL)
const Api = axios.create({
    baseURL:  API_URL
})



Api.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

Api.interceptors.request.use(
    (config) => {
        const accessToken = localStorage.getItem('tk_hsp');
        console.log(accessToken)
        if (accessToken) {
            config.headers.Authorization = `Bearer ${accessToken}`
        }
        return config;
    },
    (error) => ErrorInterceptor(error)
)

Api.interceptors.response.use(
    (response) => ResponseInterceptor(response),
    (error) => ErrorInterceptor(error)
)

export default Api;