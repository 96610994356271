import { Container } from "react-bootstrap";
import Template from "../../layout/Template";


const Usuarios = () => {

return (
    <>
    <Template>
        <Container >
        <h3>Usuários</h3>
        </Container>
    </Template>
        
    </>
)

}

export default Usuarios;